// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-crear-nueva-orden-jsx": () => import("/opt/build/repo/src/pages/crear-nueva-orden.jsx" /* webpackChunkName: "component---src-pages-crear-nueva-orden-jsx" */),
  "component---src-pages-editar-orden-jsx": () => import("/opt/build/repo/src/pages/editar-orden.jsx" /* webpackChunkName: "component---src-pages-editar-orden-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ordenes-de-compra-jsx": () => import("/opt/build/repo/src/pages/ordenes-de-compra.jsx" /* webpackChunkName: "component---src-pages-ordenes-de-compra-jsx" */),
  "component---src-pages-personalizacion-jsx": () => import("/opt/build/repo/src/pages/personalizacion.jsx" /* webpackChunkName: "component---src-pages-personalizacion-jsx" */),
  "component---src-pages-ver-orden-compra-jsx": () => import("/opt/build/repo/src/pages/ver-orden-compra.jsx" /* webpackChunkName: "component---src-pages-ver-orden-compra-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

